import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { ref } from 'vue';
export default () => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'signSelect',
      prop: 'category',
      value: '',
      options: 'goods_category_slim',
      width: 180,
      placeholder: '请选择大类',
    },
    {
      type: 'input',
      prop: 'country',
      value: '',
      placeholder: '请输入原产国',
    },
    {
      type: 'input',
      prop: 'plantNo',
      value: '',
      placeholder: '请输入厂号',
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '大类',
      prop: 'category',
      propDesc: 'categoryName', // 需要转换的列值的属性名
      width: 76,
    },
    {
      label: '原产国',
      prop: 'countryName',
      width: 150,
    },
    {
      label: '厂号',
      prop: 'plantNo',
      width: 170,
    },
    {
      label: '企业名称',
      prop: 'plantName',
      width: 340,
    },
    {
      label: '企业地址',
      prop: 'plantAddress',
      width: 480,
    },
    {
      label: '生产方式',
      prop: 'activities',
      width: 170,
    },
    {
      label: '注册品种',
      prop: 'approvalProducts',
      width: 220,
    },
  ];
  return { tableRef, filterOptions, columnList };
};
