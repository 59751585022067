
import { defineComponent } from 'vue';
import CrmTable from '@/components/CrmTable/index.vue';
import useUploadExcelData from '@/views/crm/basicInformation/factory/components/useUploadExcelData';

import { errorMessage, getSuffix, successMessage } from '@/utils/publicMethods';
import UploadErrorTable from '@/views/crm/basicInformation/factory/components/UploadErrorTable.vue';
type FileList =
  | {
      name: string;
      url: string;
    }
  | []
  | null;
export default defineComponent({
  props: {
    uploadUrl: {
      type: [String],
      default: '',
    },
  },
  emits: ['close'],
  components: { UploadErrorTable, CrmTable },
  setup(props, ctx) {
    const { filterOptions, columnList, tableRef, token, uploadRef, fileList, listQueryParam } = useUploadExcelData();
    const refreshTable = () => {
      tableRef.value.refreshTable(false);
    };
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    /** 上传成功回调 */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onSuccess = (response, file: any, fileList: any[]) => {
      // todo miniIo
      if (response.code === 200) {
        if (response.result && response.result.hasError) {
          errorMessage('数据格式不正确,请查看错误列表');
          fileList = [];
          // console.log(listQueryParam.value.logId);
          listQueryParam.value.logId = response.result.importLogId;
          console.log('listQueryParam.value', listQueryParam.value);
          // this.queryDataFn();
          refreshTable();
        } else {
          successMessage('上传成功');
          // this.$emit('success');
          close(true);
        }
      } else {
        errorMessage(response.message);
        fileList = [];
      }
    };
    const beforeAvatarUpload = (file: File) => {
      const { name, size } = file;
      const suffix = getSuffix(name);
      if (size <= 0) {
        errorMessage('上传的文件大小为0,请重新选择文件');
        return false;
      }
      if (suffix === 'xls' || suffix === 'xlsx') {
        return true;
      } else {
        errorMessage('只支持xls和xlsx文件。');
        return false;
      }
    };
    const submitUpload = () => {
      if (uploadRef.value.uploadFiles.length === 0) {
        errorMessage('请选择上传文件');
        return;
      }
      uploadRef.value.submit();
    };
    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      token,
      onSuccess,
      uploadRef,
      fileList,
      beforeAvatarUpload,
      submitUpload,
      listQueryParam,
      close,
    };
  },
});
