
import CrmList from '@/components/CrmList/CrmList.vue';
import useListData from './useListData';
import useVisible from '@/hooks/useVisible';
import { defineComponent } from 'vue';
import UploadExcelDialog from './components/UploadExcelDialog.vue';
import { exportFile, hasPermission, errorMessage } from '@/utils/publicMethods';
export default defineComponent({
  name: 'FactoryList',
  components: { CrmList, UploadExcelDialog },
  setup() {
    const { filterOptions, columnList, tableRef } = useListData();
    const refreshTable = () => {
      tableRef.value.refreshTable(false);
    };
    const uploadUrl = process.env.VUE_APP_API_ROOT + '/malicrm/approval/importApproval';
    const { visible, showVisible } = useVisible();
    const closeVisible = (status) => {
      visible.value = false;
      if (status) {
        refreshTable();
      }
    };
    // 下载表格
    const donwLoadExcel = (permission) => {
      if (hasPermission(permission)) {
        exportFile({
          type: 'POST',
          url: '/malicrm/approval/downloadTemplateForApproval',
        });
      } else {
        errorMessage('没有权限');
      }
    };
    return {
      filterOptions,
      columnList,
      tableRef,
      visible,
      uploadUrl,
      showVisible,
      closeVisible,
      refreshTable,
      donwLoadExcel,
    };
  },
});
